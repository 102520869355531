import Schedule from "components/schedule/schedule";
import Slices from "components/slices/slices";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import React, { useContext, useEffect } from "react";
import { repositoryConfig } from "utils/repositoryConfig";
import Container from "../components/container/container";
import Context from "../components/context";
import PageTitle from "../components/page-title/page-title";
import SEO from "../components/seo";
import ThumbGrid from "../components/thumb-grid/thumb-grid";

function AanbodCategoryPage({ data }) {
  const category = data.prismicAanbodCategory.data;
  const breadcrumb = [{ link: "/aanbod/", name: "Aanbod" }, { name: category.title.text }];
  const items =
    data.allPrismicAanbodItem?.nodes.map((node) => {
      return {
        key: node.uid,
        title: node.data.title.text,
        link: `/aanbod/item/${node.uid}/`,
        image: node.data.image,
      };
    }) || [];
  const context = useContext(Context);

  useEffect(() => {
    context.set({ breadcrumb: breadcrumb });
  }, []);

  return (
    <>
      <SEO title={category.title.text} description={category.page_description} />
      <PageTitle bg={category.title_bg}>{category.title.text}</PageTitle>
      <Slices slices={category.body}></Slices>
      {items.length > 0 && (
        <Container>
          <ThumbGrid items={items}></ThumbGrid>
        </Container>
      )}
      <Schedule items={data.schedule.nodes}></Schedule>
    </>
  );
}

export const Query = graphql`
  query ($uid: String!) {
    prismicAanbodCategory(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        title {
          text
        }
        page_description
        title_bg {
          thumbnails {
            xs {
              url
            }
            sm {
              url
            }
            md {
              url
            }
            lg {
              url
            }
            xl {
              url
            }
            max {
              url
            }
          }
        }
        body {
          ... on PrismicAanbodCategoryDataBodyText {
            primary {
              text {
                html
              }
            }
            slice_type
          }
        }
      }
    }
    allPrismicAanbodItem(
      filter: { data: { category: { uid: { eq: $uid } } } }
      sort: { fields: data___priority }
    ) {
      nodes {
        _previewable
        uid
        data {
          title {
            text
          }
          image {
            url
            thumbnails {
              xs {
                url
              }
              xs_2x {
                url
              }
              xs_3x {
                url
              }
            }
            dimensions {
              width
              height
            }
          }
          category {
            uid
          }
        }
      }
    }
    schedule: allPrismicAanbodItem(filter: { data: { category: { uid: { eq: $uid } } } }) {
      nodes {
        uid
        url
        data {
          title {
            text
          }
          page_description
          category {
            slug
          }
          body1 {
            ... on PrismicAanbodItemDataBody1EnkeleSessie {
              slice_type
              primary {
                date: date(locale: "nl-BE")
                day: date(formatString: "DD", locale: "nl-BE")
                month: date(formatString: "M", locale: "nl-BE")
                year: date(formatString: "YYYY", locale: "nl-BE")
                day_str: date(formatString: "dd", locale: "nl-BE")
                month_str: date(formatString: "MMM", locale: "nl-BE")
                end_hour
                end_minutes
                start_hour
                start_minutes
                remark
              }
            }
            ... on PrismicAanbodItemDataBody1MeerdereSessies {
              slice_type
              items {
                date: date(locale: "nl-BE")
                day: date(formatString: "DD", locale: "nl-BE")
                month: date(formatString: "M", locale: "nl-BE")
                year: date(formatString: "YYYY", locale: "nl-BE")
                day_str: date(formatString: "dd", locale: "nl-BE")
                month_str: date(formatString: "MMM", locale: "nl-BE")
                end_hour
                end_minutes
                start_hour
                start_minutes
                remark
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(AanbodCategoryPage, repositoryConfig);
