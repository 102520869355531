import { componentResolverFromMap } from "gatsby-plugin-prismic-previews";
import { linkResolver } from "utils/LinkResolver";
import IndexPage from "pages/index";
import DefaultPage from "templates/defaultPage";
import AanbodItemPage from "templates/aanbod-item";
import AanbodCategoryPage from "templates/aanbod-category";

export const unpublishedRepositoryConfig = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
    componentResolver: componentResolverFromMap({
      home_page: IndexPage,
      page: DefaultPage,
      aanbod_item: AanbodItemPage,
      aanbod_category: AanbodCategoryPage,
    }),
  },
];
