import React from "react";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";
import Container from "components/container/container";
import PageTitle from "components/page-title/page-title";
import ContentColumn from "components/content-column/content-column";
import { Link } from "gatsby";
import { unpublishedRepositoryConfig } from "utils/unpublishedRepositoryConfig";

const NotFoundPage = () => {
  return (
    <>
      <PageTitle>Pagina niet gevonden</PageTitle>
      <Container>
        <ContentColumn>
          <p>
            Oeps, de pagina die je zoekt lijkt niet te bestaan. <Link to="/">Klik hier</Link> om
            terug naar de homepagina te gaan.
          </p>
        </ContentColumn>
      </Container>
    </>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage, unpublishedRepositoryConfig);
