import Schedule from "components/schedule/schedule";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import React, { useContext, useEffect } from "react";
import { repositoryConfig } from "utils/repositoryConfig";
import Context from "../components/context";
import PageTitle from "../components/page-title/page-title";
import SEO from "../components/seo";
import Slices from "../components/slices/slices";

function AanbodItemPage({ data }) {
  const item = data.prismicAanbodItem.data;
  const breadcrumb = [
    { link: "/aanbod/", name: "Aanbod" },
    {
      link: `/aanbod/categorie/${item.category.uid}/`,
      name: item.category.document.data.title.text,
    },
    { name: item.title.text },
  ];
  const context = useContext(Context);

  useEffect(() => {
    context.set({ breadcrumb: breadcrumb });
  }, []);

  return (
    <>
      <SEO title={item.title.text} description={item.page_description} />
      <PageTitle bg={item.title_bg}>{item.title.text}</PageTitle>
      <Slices slices={item.body} />
      <Schedule items={data.schedule.nodes}></Schedule>
    </>
  );
}

export const Query = graphql`
  query ($uid: String!) {
    prismicAanbodItem(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        title {
          text
        }
        page_description
        category {
          uid
          document {
            ... on PrismicAanbodCategory {
              data {
                title {
                  text
                }
              }
            }
          }
        }
        title_bg {
          thumbnails {
            xs {
              url
            }
            sm {
              url
            }
            md {
              url
            }
            lg {
              url
            }
            xl {
              url
            }
            max {
              url
            }
          }
        }
        body {
          ... on PrismicAanbodItemDataBodyText {
            primary {
              text {
                html
              }
            }
            slice_type
          }
          ... on PrismicAanbodItemDataBodyImageText {
            slice_type
            primary {
              image {
                thumbnails {
                  xs {
                    url
                  }
                  xs_2x {
                    url
                  }
                  xs_3x {
                    url
                  }
                }
                alt
                url
                dimensions {
                  width
                  height
                }
              }
              text {
                html
              }
              variant
            }
          }
        }
      }
    }
    schedule: allPrismicAanbodItem(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        url
        data {
          title {
            text
          }
          page_description
          category {
            slug
          }
          body1 {
            ... on PrismicAanbodItemDataBody1EnkeleSessie {
              slice_type
              primary {
                date: date(locale: "nl-BE")
                day: date(formatString: "DD", locale: "nl-BE")
                month: date(formatString: "M", locale: "nl-BE")
                year: date(formatString: "YYYY", locale: "nl-BE")
                day_str: date(formatString: "dd", locale: "nl-BE")
                month_str: date(formatString: "MMM", locale: "nl-BE")
                end_hour
                end_minutes
                start_hour
                start_minutes
                remark
              }
            }
            ... on PrismicAanbodItemDataBody1MeerdereSessies {
              slice_type
              items {
                date: date(locale: "nl-BE")
                day: date(formatString: "DD", locale: "nl-BE")
                month: date(formatString: "M", locale: "nl-BE")
                year: date(formatString: "YYYY", locale: "nl-BE")
                day_str: date(formatString: "dd", locale: "nl-BE")
                month_str: date(formatString: "MMM", locale: "nl-BE")
                end_hour
                end_minutes
                start_hour
                start_minutes
                remark
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(AanbodItemPage, repositoryConfig);
